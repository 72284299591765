var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"intangible-container"},[_c('div',{staticClass:"intangible-content"},[_c('div',{staticClass:"intangible-switch-type"},[_c('div',{staticClass:"intangible-switch-type-title"},[_vm._v("类型：")]),_c('div',{staticClass:"intangible-switch-type-content"},_vm._l((_vm.typeList),function(item,index){return _c('div',{key:item.id,staticClass:"intangible-switch-type-content-li",class:{
            'intangible-switch-type-content-li-active': _vm.current == index,
          },on:{"click":function($event){return _vm.handleChangeValue(index, item)}}},[_vm._v(" "+_vm._s(item.category_name)+" ")])}),0)]),(_vm.levelList.length > 0)?_c('div',{staticClass:"intangible-switch-type"},[_c('div',{staticClass:"intangible-switch-type-title"},[_vm._v("类型：")]),_c('div',{staticClass:"intangible-switch-type-content"},_vm._l((_vm.levelList),function(item,index){return _c('div',{key:index,staticClass:"intangible-switch-type-content-li",class:{
            'intangible-switch-type-content-li-active': _vm.curr == index,
          },on:{"click":function($event){return _vm.handleChange(index, item.id)}}},[_vm._v(" "+_vm._s(item.category_name)+" ")])}),0)]):_vm._e(),_c('div',{staticClass:"intangible-content-hot-new"},[_vm._m(0),_c('div',{staticClass:"intangible-content-hot-new-title",on:{"click":function($event){return _vm.handleChangeValue2('sort', 0)}}},[_c('div',{style:({
            'font-weight': _vm.value2 == 'sort' ? 'bold' : '',
          })},[_vm._v(" 默认 ")]),_c('div',{staticClass:"intangible-content-hot-new-title-bg",style:({
            display: _vm.value2 == 'sort' ? 'block' : 'none',
          })})]),_c('div',{staticClass:"intangible-content-new-mid-hot"}),_c('div',{staticClass:"intangible-content-hot-new-title",on:{"click":function($event){return _vm.handleChangeValue2('id', 1)}}},[_c('div',{style:({
            'font-weight': _vm.value2 == 'id' ? 'bold' : '',
          })},[_vm._v(" 最新 ")]),_c('div',{staticClass:"intangible-content-hot-new-title-bg",style:({
            display: _vm.value2 == 'id' ? 'block' : 'none',
          })})]),_c('div',{staticClass:"intangible-content-new-mid-hot"}),_c('div',{staticClass:"intangible-content-hot-new-title intangible-content-hot-new-title1",on:{"click":function($event){return _vm.handleChangeValue2('view_count', 2)}}},[_c('div',{style:({ 'font-weight': _vm.value2 == 'view_count' ? 'bold' : '' })},[_vm._v(" 最热 ")]),_c('div',{staticClass:"intangible-content-hot-new-title-bg",style:({ display: _vm.value2 == 'view_count' ? 'block' : 'none' })})]),_vm._m(1)]),(_vm.content && _vm.content.length !== 0)?_c('div',{staticClass:"intangible-tab-content clear"},[_c('listItem',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.listLoading),expression:"listLoading"}],attrs:{"listType":"intangible","list":_vm.content}}),_c('Pagination',{attrs:{"total":_vm.count,"page":_vm.page,"limit":_vm.limit},on:{"update:page":function($event){_vm.page=$event},"update:limit":function($event){_vm.limit=$event},"pagination":function($event){return _vm.loadList(_vm.cat_id)}}})],1):_c('Nocontent',{attrs:{"title":"暂无非遗保护，请持续关注哦～"}})],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"intangible-content-hot-new-icon"},[_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":"https://image.bookgo.com.cn/webculture/jm/title_left_icon.png","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"intangible-content-hot-new-icon"},[_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":"https://image.bookgo.com.cn/webculture/jm/title_right_icon.png","alt":""}})])
}]

export { render, staticRenderFns }