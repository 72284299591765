<template>
  <div class="intangible-container">
    <div class="intangible-content">
      <div class="intangible-switch-type">
        <div class="intangible-switch-type-title">类型：</div>
        <div class="intangible-switch-type-content">
          <div
            class="intangible-switch-type-content-li"
            :class="{
              'intangible-switch-type-content-li-active': current == index,
            }"
            v-for="(item, index) in typeList"
            :key="item.id"
            @click="handleChangeValue(index, item)"
          >
            {{ item.category_name }}
          </div>
        </div>
      </div>
      <div class="intangible-switch-type" v-if="levelList.length > 0">
        <div class="intangible-switch-type-title">类型：</div>
        <div class="intangible-switch-type-content">
          <div
            class="intangible-switch-type-content-li"
            :class="{
              'intangible-switch-type-content-li-active': curr == index,
            }"
            v-for="(item, index) in levelList"
            :key="index"
            @click="handleChange(index, item.id)"
          >
            {{ item.category_name }}
          </div>
        </div>
      </div>

      <!-- 最新·最热 -->
      <div class="intangible-content-hot-new">
        <div class="intangible-content-hot-new-icon">
          <img
            style="width: 100%; height: 100%"
            src="https://image.bookgo.com.cn/webculture/jm/title_left_icon.png"
            alt=""
          />
        </div>
        <div
          class="intangible-content-hot-new-title"
          @click="handleChangeValue2('sort', 0)"
        >
          <div
            :style="{
              'font-weight': value2 == 'sort' ? 'bold' : '',
            }"
          >
            默认
          </div>
          <div
            class="intangible-content-hot-new-title-bg"
            :style="{
              display: value2 == 'sort' ? 'block' : 'none',
            }"
          ></div>
        </div>
        <div class="intangible-content-new-mid-hot"></div>
        <div
          class="intangible-content-hot-new-title"
          @click="handleChangeValue2('id', 1)"
        >
          <div
            :style="{
              'font-weight': value2 == 'id' ? 'bold' : '',
            }"
          >
            最新
          </div>
          <div
            class="intangible-content-hot-new-title-bg"
            :style="{
              display: value2 == 'id' ? 'block' : 'none',
            }"
          ></div>
        </div>
        <div class="intangible-content-new-mid-hot"></div>
        <div
          class="intangible-content-hot-new-title intangible-content-hot-new-title1"
          @click="handleChangeValue2('view_count', 2)"
        >
          <div :style="{ 'font-weight': value2 == 'view_count' ? 'bold' : '' }">
            最热
          </div>
          <div
            class="intangible-content-hot-new-title-bg"
            :style="{ display: value2 == 'view_count' ? 'block' : 'none' }"
          ></div>
        </div>
        <div class="intangible-content-hot-new-icon">
          <img
            style="width: 100%; height: 100%"
            src="https://image.bookgo.com.cn/webculture/jm/title_right_icon.png"
            alt=""
          />
        </div>
      </div>

      <!-- 类型的tab切换中的假内容 -->
      <div
        class="intangible-tab-content clear"
        v-if="content && content.length !== 0"
      >
        <listItem
          listType="intangible"
          :list="content"
          v-loading="listLoading"
        ></listItem>

        <!-- 分页组件 -->
        <Pagination
          :total="count"
          :page.sync="page"
          :limit.sync="limit"
          @pagination="loadList(cat_id)"
        />
      </div>
      <!-- 没有内容显示的页面 -->
      <Nocontent v-else title="暂无非遗保护，请持续关注哦～"></Nocontent>
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination/index.vue";
import ListItem from "@/views/components/listItem";
import Nocontent from "@/views/components/Nocontent";
import {
  getIntangibleTypeList,
  getResourceList,
} from "../../api/intangible/intangible";
export default {
  name: "intangible",
  data() {
    return {
      current: 0,
      curr: 0,
      cur: 0,
      page: 1,
      limit: 6,
      count: 0,
      getChild: 1,
      cat_id: "",
      typeList: [],
      levelList: [],
      listLoading: true,
      sortField: ["sort", "id", "view_count"],
      content: [],
      listQuery: {
        aid: process.env.VUE_APP_AID,
        class_id: 1,
      },
      params: {
        aid: process.env.VUE_APP_AID,
        class_id: process.env.CLASS_ID,
      },
      value2: "sort",
    };
  },
  components: {
    Pagination,
    ListItem,
    Nocontent,
  },
  created() {
    this.loadList(0);
  },
  watch: {
    $route() {
      this.cat_id = this.$route.query.cat_id;
    },
    cat_id(newV, oldV) {
      console.log(newV, oldV);
      this.cat_id = newV;
      this.getCategory();
      this.loadList(newV);
    },
  },
  mounted() {
    window.scroll(0, 0);
    this.cat_id = this.$route.query.cat_id ? this.$route.query.cat_id : 0;
    this.getCategory();
  },
  methods: {
    handleChange(index, id) {
      this.page = 1;
      this.curr = index;
      this.cat_id = id;
      this.loadList(id);
    },
    handleClickLabel(i, id) {
      this.current = i;
      if (id > 0) {
        this.category_id = id;
      } else {
        this.category_id = null;
      }
      this.loadList();
    },
    handleChangeValue(index, item) {
      var id = item.children_data ? item.children_data[0].id : item.id;
      this.page = 1;

      this.current = index;
      this.cat_id = id;
      this.curr = 0;
      this.levelList = item.children_data ? item.children_data : [];
      this.loadList(id);

      // this.getCategory()
    },
    handleChangeValue2(value2, i) {
      this.page = 1;
      this.value2 = value2;
      this.loadList(i);
    },
    getCategory() {
      this.getlables().then((res) => {
        if (res.code == 0) {
          // res.data.forEach((item) => {
          //   this.levelList = this.levelList.concat(item.children_data);
          // });
          // var obj = {};
          // this.levelList = this.levelList.reduce(function(item, next) {
          //   obj[next.id] ? "" : (obj[next.id] = true && item.push(next));
          //   return item;
          // }, []);
          // console.log(this.levelList);
          // res.data.unshift({ category_name: "全部", id: 0 });
          this.typeList = res.data;
          console.log(this.typeList)
          res.data.forEach((item, index) => {
            if (item.id == this.cat_id) {
              this.current = index;
            }
          });
          if(res.data[this.current].children_data.length > 0) {
            this.levelList = res.data[this.current].children_data;
            this.cat_id = res.data[this.current].children_data[this.curr].id
          }else {
            this.levelList = res.data[this.current].children_data;
          }
          
          this.loadList(this.levelList[this.curr].id);
        }
      });
    },
    loadList(id) {
      let that = this;
      this.listLoading = true;
      let params = Object.assign({}, this.params, {
        page: this.page,
        limit: this.limit,
        sort_field: this.value2,
        category_id: id ? id : "",
        class_id: 1,
        get_children_list: this.getChild,
      });
      getResourceList(params).then((res) => {
        this.listLoading = false;
        console.log(res);
        if (res.code == 0) {
          that.content = res.data.datalist;
          that.count = res.data.count;
        }
      });
    },
    getlables() {
      return getIntangibleTypeList(this.listQuery);
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/scss/intangible.scss";
</style>
