import request from '@/utils/request'

// 获取非遗文化类型
export function getIntangibleTypeList(data) {
    return request({
        url: '/v2/culture/resource.Category/lists',
        method: 'get',
        params: data
    })
}

// 获取类型后 再获取资源列表

export function getResourceList(data) {
    return request({
        url: '/v2/culture/resource/lists',
        method: 'get',
        params: data
    })
}